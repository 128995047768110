import React from 'react'
import {Container, Row, Table} from 'react-bootstrap'
import {Map, Circle, Popup, Marker, TileLayer, ZoomControl} from 'react-leaflet'
import Cookies from 'js-cookie'
import 'react-leaflet-fullscreen/dist/styles.css'
import {withRouter, Redirect} from 'react-router-dom'
import 'react-leaflet-fullscreen/dist/styles.css'
import FullscreenControl from 'react-leaflet-fullscreen'
import "leaflet/dist/leaflet.css"
import L from "leaflet"
import StateStats from '../StateStats/StateStats'
const Constants = require('../../Constants')

const customMarker = new L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
})

const DEFAULT_VIEWPORT = {
    center: [23.4358, 73.8463],
    zoom: 13,
  }

const DEFAULT_BOUNDS = [
  [8.4, 97.25],
  [37.6, 68.7]];

class PlotMap extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            districtsData: '',
            coordinates: '',
            maxConfirmed: 1,
            states: []
        }
        this.fetchDistrictCoordinates = this.fetchDistrictCoordinates.bind(this)
        this.fetchDistrictsCovidData = this.fetchDistrictsCovidData.bind(this)
        this.handleStateMarker = this.handleStateMarker.bind(this)
    }

    fetchDistrictCoordinates(){
        fetch('/api/district-coordinates', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res=> {
            if (res.status === 200){
                res.json()
                .then(res=>{
                    if (!res.error){
                        this.setState({coordinates: res.result})
                    }
                })
            }
        })
    }

    fetchDistrictsCovidData(){
        const proxy_url = "https://cors-anywhere.herokuapp.com/"
        fetch(proxy_url + 'https://api.covid19india.org/v3/data.json', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res=>{
            if (res.status === 200){
                res.json()
                .then(res => {
                    const states = Object.keys(res)
                    const allDistrictsData = []
                    let maxConfirmed = 1 
                    states.map(state => {
                        if (res[state]){
                            if (Object.keys(res[state]).includes('districts')){
                                const districts = Object.keys(res[state].districts)
                                districts.map(district => {
                                    const data = res[state].districts[district]
                                    const districtData = {
                                        district: district
                                    }
                                    if (Object.keys(data).includes('total')){
                                        if (Object.keys(data.total).includes('confirmed')){
                                            districtData.confirmed = data.total.confirmed
                                            if (data.total.confirmed > maxConfirmed){
                                                maxConfirmed = data.total.confirmed
                                            }
                                        }
                                        if (Object.keys(data.total).includes('recovered')){
                                            districtData.recovered = data.total.recovered
                                        }
                                        if (Object.keys(data.total).includes('deceased')){
                                            districtData.deceased = data.total.deceased
                                        }
                                        if (Object.keys(data.total).includes('tested')){
                                            districtData.tested = data.total.tested
                                        }
                                        allDistrictsData.push(districtData)
                                    }
                                })
                            }
                        }
                    })

                    this.setState({
                        districtsData: allDistrictsData,
                        maxConfirmed: maxConfirmed,
                        states: states, //state codes
                    })
                })
            }
        })
    }

    componentDidMount(){
        this.fetchDistrictCoordinates()
        this.fetchDistrictsCovidData()
        Cookies.remove('state')
    }

    handleStateMarker(event){
        const selectedState = event.target.options.children.props.children.props.children[0].props.children
        Cookies.set('state', selectedState[1])
    }

    render(){
        const districtCircles = []
        if (this.state.districtsData && this.state.coordinates){
            this.state.districtsData.map(districtsData => {
                if (Object.keys(this.state.coordinates).includes(districtsData.district)){
                    let circleRadius = 10000 + 90000*Number(districtsData.confirmed)/Number(this.state.maxConfirmed)
                    if (isNaN(circleRadius)){
                        circleRadius = 10000
                    }
                    districtCircles.push(
                        <Circle key={'district_' + Math.floor(Math.random()*1e20)}
                            center={[Number(this.state.coordinates[districtsData.district].latitude), Number(this.state.coordinates[districtsData.district].longitude)]}
                            color="#0080FF"
                            fillOpacity={0.5}
                            radius={circleRadius}
                            onmouseover={(e) => {
                                e.target.openPopup();
                            }}
                            onmouseout={(e) => {
                                e.target.closePopup();
                            }}>
                            <Popup style={{width:"auto", maxHeight:"auto"}}>
                                <Container>
                                    <Row>
                                        <Table striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <td>District</td>
                                                    <td>{districtsData.district}</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(districtsData).includes('confirmed')?<tr>
                                                    <td>Confirmed</td>
                                                    <td>{districtsData.confirmed}</td>
                                                </tr>:''}
                                                {Object.keys(districtsData).includes('deceased')?<tr>
                                                    <td>Deceased</td>
                                                    <td>{districtsData.deceased}</td>
                                                </tr>:''}
                                                {Object.keys(districtsData).includes('recovered')?<tr>
                                                    <td>Recovered</td>
                                                    <td>{districtsData.recovered}</td>
                                                </tr>:''}
                                                {Object.keys(districtsData).includes('tested')?<tr>
                                                    <td>Tested</td>
                                                    <td>{districtsData.tested}</td>
                                                </tr>:''}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Container>
                            </Popup>
                        </Circle>
                    )
                }
            })
        }
        const stateMarkers = []
        if (this.state.states.length > 0 && this.state.coordinates){
            this.state.states.map(stateCode=>{
                const stateName = Constants.stateCodeToName(stateCode)
                if (Object.keys(this.state.coordinates).includes(stateName)){
                    stateMarkers.push(
                        <Marker key={'marker_' + Math.floor(Math.random()*1e10)}
                            position={[Number(this.state.coordinates[stateName].latitude), Number(this.state.coordinates[stateName].longitude)]}
                            color="blue"
                            icon={customMarker}
                            onclick={(e) => {
                                e.target._latlng = [6,80]
                                e.target.openPopup();
                            }}
                            >
                            <Popup po style={{maxWidth:"auto", maxHeight:"auto"}}>
                                <StateStats style={{width:"500px"}} stateName={stateName}/>
                            </Popup>
                        </Marker>
                    )
                }
            })
        }
        let mapStyle = {
            width: window.innerWidth,
            height: window.innerHeight,
        }
        if (window.innerWidth > 900){
            mapStyle = {
                height: window.innerHeight,
                width: window.innerWidth*0.7
            }
        }
        return(
            <Container fluid>
                <Row>
                    <Map position={[23, 80]}
                         bounds={DEFAULT_BOUNDS}
                         zoomControl={true}
                         scrollWheelZoom={false}
                         style={mapStyle}>
                        <FullscreenControl position="topright"/>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                   attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"/>
                        {stateMarkers}
                        {districtCircles}
                    </Map>
                </Row>
            </Container>

        )
    }
}

export default withRouter(PlotMap)