const state_name_lookup = {
    AN: 'Andaman and Nicobar Islands',
    AP: 'Andhra Pradesh',
    AR: 'Arunachal Pradesh',
    AS: 'Assam',
    BR: 'Bihar',
    CH: 'Chandigarh',
    CT: 'Chattisgarh',
    DN: 'Dadra and Nagar Haveli',
    DD: 'Daman and Diu',
    DL: 'Delhi',
    GA: 'Goa',
    GJ: 'Gujarat',
    HR: 'Haryana',
    HP: 'Himachal Pradesh',
    JK: 'Jammu and Kashmir',
    JH: 'Jharkhand',
    KA: 'Karnataka',
    KL: 'Kerala',
    LD: 'Lakshadweep',
    MP: 'Madhya Pradesh',
    MH: 'Maharashtra',
    MN: 'Manipur',
    ML: 'Meghalaya',
    MZ: 'Mizoram',
    NL: 'Nagaland',
    OR: 'Odisha',
    PY: 'Puducherry',
    PB: 'Punjab',
    RJ: 'Rajasthan',
    SK: 'Sikkim',
    TN: 'Tamil Nadu',
    TG: 'Telangana',
    TR: 'Tripura',
    UP: 'Uttar Pradesh',
    UT: 'Uttarakhand',
    WB: 'West Bengal',
    LA: 'Ladakh'
}

const monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const stateCodeToName = (code) => {
    return state_name_lookup[code]
}

const stateNameToCode = (stateName) => {
    console.log(stateName)
    const listOfStateCodes = Object.keys(state_name_lookup)
    let stateCode = ''
    for (let i = 0; i<listOfStateCodes.length; i++){
        if (state_name_lookup[listOfStateCodes[i]] === stateName){
            stateCode = listOfStateCodes[i]
        }
    }
    return stateCode
}

exports.stateCodeToName = stateCodeToName
exports.state_name_lookup = state_name_lookup
exports.stateNameToCode = stateNameToCode
exports.monthsArray = monthsArray