import React from 'react'
import CanvasJSReact from '../../lib/canvasjs-2.3.2/canvasjs.react'
import {Container, Button, ButtonGroup} from 'react-bootstrap'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

const buttonStyle = (status) => {
    const style = {
      margin:"2px", 
      padding:"2px", 
      fontSize:"0.8em"
    }
    if (!status){
      style.color = '#0080ff'
      style.backgroundColor = 'white'
      style.fontWeight = 700
      style.border = "2px solid #0080ff"
    }
    return(style)
  }

class DataChart extends React.Component{
    constructor(props){
      super(props)
      this.state = {
        activeButtons: ['confirmed', 'deceased', 'recovered'],
      }
      this.handleCaseType = this.handleCaseType.bind(this)
    }
  
    handleCaseType(event){
      let activeButtons = this.state.activeButtons
      if (activeButtons.includes(event.target.name)){
        activeButtons.splice(activeButtons.indexOf(event.target.name), 1)
      }
      else{
        activeButtons.push(event.target.name)
      }
      this.setState({activeButtons: activeButtons})
    }
  
    render(){
      const chartOptions = {
        animationEnabled: true,
        height: 300,
        title:{
          text: ""
        },
        axisX: {
          valueFormatString: "MMM"
        },
        axisY: {
          title: "Number of Cases",
          prefix: "",
          includeZero: false
        },
        data: []
      }
      const chartData = {
        yValueFormatString: "###",
        xValueFormatString: "DDMMMM",
        type: "line",
        dataPoints: []
      }

      if (window.innerWidth>700){
        chartOptions.height = window.innerHeight*0.3
      }
  
      if (this.state.activeButtons.includes('confirmed')){
        const confirmed_chart_options = {...chartData}
        confirmed_chart_options.dataPoints = this.props.confirmed_array
        chartOptions.data.push(confirmed_chart_options)
      }
      
      if (this.state.activeButtons.includes('deceased')){
        const deceased_chart_options = {...chartData}
        deceased_chart_options.dataPoints = this.props.deceased_array
        chartOptions.data.push(deceased_chart_options)
      }
      
      if (this.state.activeButtons.includes('recovered')){
        const recovered_chart_options = {...chartData}
        recovered_chart_options.dataPoints = this.props.recovered_array
        chartOptions.data.push(recovered_chart_options) 
      }
      
      return(
        <Container fluid style={{textAlign:"center"}}>
            <CanvasJSChart options={chartOptions}/>
            <ButtonGroup>
              <Button style={buttonStyle(this.state.activeButtons.includes('confirmed'))} variant="primary" name="confirmed" onClick={this.handleCaseType}>Confirmed</Button>
              <Button style={buttonStyle(this.state.activeButtons.includes('deceased'))} variant="primary" name="deceased" onClick={this.handleCaseType}>Deceased</Button>
              <Button style={buttonStyle(this.state.activeButtons.includes('recovered'))} variant="primary" name="recovered" onClick={this.handleCaseType}>Recovered</Button>
            </ButtonGroup>
        </Container>
      )
    }
}

export default DataChart