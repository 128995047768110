import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import DataChart from './Components/DataChart/DataChart'
import StatCards from './Components/StatCards/StatCards'
import PlotMap from './Components/PlotMap/PlotMap'
import ReactGA from 'react-ga'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

const Constants = require('./Constants')

const monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

class App extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      todays_data: {
        dailyconfirmed: '',
        dailydeceased: '',
        dailyrecovered: '',
        date: '',
        totalconfirmed: '',
        totaldeceased: '',
        totalrecovered: '',
      },
      cumulative_confirmed_array: [],
      cumulative_deceased_array: [],
      cumulative_recovered_array: [],
      daily_confirmed_array: [],
      daily_deceased_array: [],
      daily_recovered_array: [], 
      states_data: []
    }
  }

  componentDidMount(){
    ReactGA.initialize('UA-163648196-1');
    ReactGA.pageview(window.location.pathname + window.location.search)
    fetch('https://api.covid19india.org/data.json', {
      method: 'GET',
      header: {
        'Content-Type': 'application/json'
      }
    })
    .then(res=>{
      if (res.status === 200){
        res.json()
        .then(res=>{
          const todays_data = res.cases_time_series[res.cases_time_series.length - 1]
          const cumulative_confirmed_array = []
          const cumulative_deceased_array = []
          const cumulative_recovered_array = []
          const daily_confirmed_array = []
          const daily_deceased_array = []
          const daily_recovered_array = []
          const states_data = []
          res.cases_time_series.map(data=>{
            const [day, month] = data.date.split(' ')
            const date = new Date(2020, monthsArray.indexOf(month), Number(day))
            cumulative_confirmed_array.push({x: date, y:Number(data.totalconfirmed)})
            cumulative_deceased_array.push({ x: date, y:Number(data.totaldeceased)})
            cumulative_recovered_array.push({x: date, y: Number(data.totalrecovered)})
            daily_confirmed_array.push({x: date, y: Number(data.dailyconfirmed)})
            daily_deceased_array.push({ x: date, y: Number(data.dailydeceased)})
            daily_recovered_array.push({x: date, y: Number(data.dailyrecovered)})
          })
          res.statewise.map(stateData => {
            if (Object.keys(Constants.state_name_lookup).includes(stateData.statecode)){
              states_data.push({
                state: stateData.state,
                confirmed: stateData.confirmed,
                deceased: stateData.deaths,
                recovered: stateData.recovered,
              })
            }
          })
          this.setState({
            todays_data,
            cumulative_confirmed_array,
            cumulative_deceased_array,
            cumulative_recovered_array,
            daily_confirmed_array,
            daily_deceased_array,
            daily_recovered_array,
            states_data
          })
        })
      }
    })
  }

  render(){
    const cumulativeDataStats = <StatCards heading="Cumulative Data" 
                                           totalconfirmed={this.state.todays_data.totalconfirmed} 
                                           totaldeceased={this.state.todays_data.totaldeceased} 
                                           totalrecovered={this.state.todays_data.totalrecovered}/>
    const todaysDataStats = <StatCards heading="Todays Data" 
                                       totalconfirmed={this.state.todays_data.dailyconfirmed} 
                                       totaldeceased={this.state.todays_data.dailydeceased} 
                                       totalrecovered={this.state.todays_data.dailyrecovered}/>
    const cumulativeDataChart = <DataChart confirmed_array={this.state.cumulative_confirmed_array} 
                                           deceased_array={this.state.cumulative_deceased_array} 
                                           recovered_array={this.state.cumulative_recovered_array}/>
    const dailyDataChart = <DataChart confirmed_array={this.state.daily_confirmed_array} 
                                      deceased_array={this.state.daily_deceased_array} 
                                      recovered_array={this.state.daily_recovered_array}/>
    const updatedDateMessage = <p style={{fontSize:"0.8rem", fontStyle:"italic"}}>Covid-19 India data as updated on {this.state.todays_data.date}, 2020</p>
    return (
      <Container fluid>
        {window.innerWidth>900?
          <Container fluid style={{marginBottom:"5px"}}>
              <Row>
                <Col md={4}>
                  <Row>{updatedDateMessage}</Row>
                  <Row>{cumulativeDataStats}</Row>
                  <Row>{cumulativeDataChart}</Row>
                  <Row>{todaysDataStats}</Row>
                  <Row>{dailyDataChart}</Row>
                </Col>
                <Col>
                  <PlotMap/>
                </Col>
              </Row>
          </Container>
          :
          window.innerWidth > 600?
            <Container fluid style={{marginBottom:"10px"}}>
            <Row>{updatedDateMessage}</Row>
            <Row>
              <Col style={{border:"1px solid #0080ff"}}>
                <Row>{cumulativeDataStats}</Row>
                <Row>{cumulativeDataChart}</Row>
              </Col>
              <Col style={{border:"1px solid #0080ff"}}>
                <Row>{todaysDataStats}</Row>
                <Row>{dailyDataChart}</Row>
              </Col>
            </Row>
            <Row><PlotMap/></Row>
          </Container>
        :
          <Container fluid style={{marginBottom:"10px"}}>
            <Row>{updatedDateMessage}</Row>
            <Row>{cumulativeDataStats}</Row>
            <Row>{cumulativeDataChart}</Row>
            <Row>{todaysDataStats}</Row>
            <Row>{dailyDataChart}</Row>
            <Row><PlotMap/></Row>
          </Container>
        }
        <Row style={{width:"100vw", backgroundColor:"#0080ff", color:"white", fontSize:"0.8rem", fontWeight:700}}>
          <p style={{padding:"5px", paddingRight:"20px"}}>Developed by Divyendu Narayan</p>
        </Row>
      </Container>
    )
  }
}


export default App