import React from 'react'
import {Container, Row, Col, Card} from 'react-bootstrap'

class StatCards extends React.Component{
    render(){
      return(
        <div style={{display:"flex", width:"100%", flexDirection:"row", alignItems:"center", justifyContent:"space-evenly"}}>
              <Card style={{textAlign:"center", background:"linear-gradient(deepskyblue, cyan)", maxHeight:"4rem"}}>
                <Card.Body style={{padding: "3px", margin:"2px", fontSize:"1rem"}}>
                  Confirmed<br/>{this.props.totalconfirmed}
                </Card.Body>
              </Card>
              <Card style={{textAlign:"center", background:"linear-gradient(red, orange)", maxHeight:"4rem"}}>
                <Card.Body style={{padding: "3px", margin:"2px", fontSize:"1rem"}}>
                  Deceased<br/>{this.props.totaldeceased}
                </Card.Body>
              </Card>
              <Card style={{textAlign:"center", background:"linear-gradient(forestgreen, lawngreen)", maxHeight:"4rem"}}>
                <Card.Body style={{padding: "3px", margin:"2px", fontSize:"1rem"}}>
                  Recovered<br/>{this.props.totalrecovered}
                </Card.Body>
              </Card>
        </div>
      )
    }
  }

export default StatCards