import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter, Route} from 'react-router-dom'
import StateStats from './Components/StateStats/StateStats'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Route exact  path="/" component={App}/>
      <Route path="/covid-state-stats" component={StateStats}/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
