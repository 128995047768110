import React from "react";
import { withRouter } from "react-router-dom";
import {Button, Container, Row, Col, ButtonGroup, Table} from 'react-bootstrap'
import CanvasJSReact from '../../lib/canvasjs-2.3.2/canvasjs.react'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

const buttonStyle = (status) => {
  const style = {
    margin:"2px", 
    padding:"2px", 
    fontSize:"0.8em"
  }
  if (!status){
    style.color = '#0080ff'
    style.backgroundColor = 'white'
    style.fontWeight = 700
    style.border = "2px solid #0080ff"
  }
  return(style)
}

const transformToChartArray = (result, fieldname) =>{
  const chartArray = []
  result[fieldname].map(data => {
    chartArray.push({
      x: new Date(data.date),
      y: Number(data.cases)
    })
  })
  return chartArray
}

class StateStats extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      confirmed_array: [],
      deceased_array: [],
      recovered_array: [],
      tested_array: [],
      activeButtons: ['confirmed', 'deceased', 'recovered'],
    }
    this.fetchStateStatistics = this.fetchStateStatistics.bind(this)
    this.handleCaseType = this.handleCaseType.bind(this)
  }

  handleCaseType(event){
    let activeButtons = this.state.activeButtons
    if (activeButtons.includes(event.target.name)){
      activeButtons.splice(activeButtons.indexOf(event.target.name), 1)
    }
    else{
      activeButtons.push(event.target.name)
    }
    this.setState({activeButtons: activeButtons})
  }

  fetchStateStatistics(state){
    fetch(`/api/state-covid-statistics?state=${state}`,{
      method:'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res=>{
      if (res.status === 200){
        res.json()
        .then(res=>{
          this.setState({
            confirmed_array: transformToChartArray(res.result, 'confirmed'),
            deceased_array:  transformToChartArray(res.result, 'deceased'),
            recovered_array: transformToChartArray(res.result, 'recovered'),
            tested_array:    transformToChartArray(res.result, 'tested'),
            
          })
        })
      }
    })
  }

  componentDidMount(){
    this.fetchStateStatistics(this.props.stateName)
  }

  render(){
    const chartOptions = {
      animationEnabled: true,
      width: 300,
      height: 300,
      zIndex: 100,
      title:{
        text: ""
      },
      axisX: {
        valueFormatString: "MMM"
      },
      axisY: {
        title: "Number of Cases",
        prefix: "",
        includeZero: false
      },
      data: []
    }

    const chartData = {
      yValueFormatString: "###",
      xValueFormatString: "DDMMMM",
      type: "line",
      dataPoints: []
    }

    if (this.state.activeButtons.includes('confirmed')){
      const confirmed_chart_options = {...chartData}
      confirmed_chart_options.dataPoints = this.state.confirmed_array
      chartOptions.data.push(confirmed_chart_options)
    }

    if (this.state.activeButtons.includes('deceased')){
      const deceased_chart_options = {...chartData}
      deceased_chart_options.dataPoints = this.state.deceased_array
      chartOptions.data.push(deceased_chart_options)
    }

    if (this.state.activeButtons.includes('recovered')){
      const recovered_chart_options = {...chartData}
      recovered_chart_options.dataPoints = this.state.recovered_array
      chartOptions.data.push(recovered_chart_options)
    }

    if (this.state.activeButtons.includes('tested')){
      const tested_chart_options = {...chartData}
      tested_chart_options.dataPoints = this.state.tested_array
      chartOptions.data.push(tested_chart_options)
    }
    

    return(
      <Container style={{width:"500px"}} style={{width:"300px"}}>
        <Row>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>State</th>
                <th>{this.props.stateName}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.confirmed_array.length > 0?
                <tr>
                  <td>Confirmed</td>
                  <td>{this.state.confirmed_array[this.state.confirmed_array.length - 1].y}</td>
                </tr>
                :
                ''
              }
              {this.state.deceased_array.length > 0?
                <tr>
                  <td>Deceased</td>
                  <td>{this.state.deceased_array[this.state.deceased_array.length - 1].y}</td>
                </tr>
                :
                ''
              }
              {this.state.recovered_array.length > 0?
                <tr>
                  <td>Recovered</td>
                  <td>{this.state.recovered_array[this.state.recovered_array.length - 1].y}</td>
                </tr>
                :
                ''
              }
              {this.state.tested_array.length > 0?
                <tr>
                  <td>Tested</td>
                  <td>{this.state.tested_array[this.state.tested_array.length - 1].y}</td>
                </tr>
                :
                ''
              }
            </tbody>
          </Table>
        </Row> 
        <Row><CanvasJSChart options={chartOptions}/></Row>
        <Row>
        <ButtonGroup>
              <Button style={buttonStyle(this.state.activeButtons.includes('confirmed'))} variant="primary" name="confirmed" onClick={this.handleCaseType}>Confirmed</Button>
              <Button style={buttonStyle(this.state.activeButtons.includes('deceased'))} variant="primary" name="deceased" onClick={this.handleCaseType}>Deceased</Button>
              <Button style={buttonStyle(this.state.activeButtons.includes('recovered'))} variant="primary" name="recovered" onClick={this.handleCaseType}>Recovered</Button>
              <Button style={buttonStyle(this.state.activeButtons.includes('tested'))} variant="primary" name="tested" onClick={this.handleCaseType}>Tested</Button>
            </ButtonGroup>
        </Row>
      </Container>
    )
  }
}

export default withRouter(StateStats)